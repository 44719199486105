import React from 'react';

const ExampleSection = () => {
  return (
    <section className="py-12 bg-white-50">
      {/* Question Section */}
      <div className="max-w-4xl mx-auto text-center mb-8">
        <h2 className="text-3xl font-semibold text-gray-800">
          Here's an example
        </h2>
      </div>

      {/* Question Section (Styled as a search box) */}
      <div className="max-w-4xl mx-auto mb-8">
        <div className="relative">
          <input
            type="text"
            readOnly
            value="My HF radio is broken, can I fly?"
            className="w-full p-4 bg-gray-100 text-gray-700 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {/* AI Response Section */}
      <div className="max-w-4xl mx-auto bg-gray-200 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">AI Response:</h3>
        <p className="text-base text-gray-600 leading-relaxed">
        Under <strong>Part 135 regulations</strong>, flying with inoperative equipment, including an HF radio, is permitted under specific conditions:<br />
            <ol class="list-decimal ml-8">
                <li>Repair Facility Unavailability: The flight may commence if there is no facility at the departure aerodrome to repair or replace the inoperative HF radio​(part-135-mos).</li>
                <li>Time Restriction: The flight must occur within 72 hours of the HF radio being identified as inoperative​(part-135-mos).</li>
            </ol>
        </p>
        <br />
        <p className="text-base text-gray-600 leading-relaxed">
        Under <strong>company regulations</strong>, flying with inoperative equipment, including an HF radio, is permitted under specific conditions:<br />
            <ol class="list-decimal ml-8">
                <li>Operational Considerations: The flight must remain within areas where communication can be maintained using alternative means (e.g., VHF or satellite communication systems if operational)​(part-135-mos).</li>
                <li>Minimum Equipment List (MEL): If the aircraft is operating under an MEL, it must explicitly allow for the HF radio to be inoperative under specified conditions​(part-135-mos).</li>
            </ol>
        </p>
        <br />
        <p className="text-base text-gray-600 leading-relaxed">
        <strong>Company best practice</strong> if you are travelling into a HF frequency area is to redirect the route or cancel the flight in order to get back to base.<br />
        </p>
      </div>
    </section>
  );
};

export default ExampleSection;
