import React, { useRef } from 'react';
import HeroSection from './components/HeroSection.tsx';
import AboutSection from './components/AboutSection.tsx';
import ExampleSection from './components/ExampleSection.tsx';
import FeaturesSection from './components/FeaturesSection.tsx';
import ContactSection from './components/ContactSection.tsx';

const App = () => {
  const contactRef = useRef<HTMLDivElement | null>(null);

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
  <div className="font-sans">
    <HeroSection onContactClick={scrollToContact} />
    <AboutSection />
    <ExampleSection />
    <FeaturesSection />
    <ContactSection ref={contactRef} />
  </div>
  );
};

export default App;
