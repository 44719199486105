import React from 'react';

const ContactSection = React.forwardRef<HTMLDivElement, {}> ((_, ref) => {
  return (
  <section ref={ref} className="py-12 bg-blue-50">
    <h2 className="text-3xl font-semibold text-center text-blue-800">Get in Touch</h2>
    {/* <form
      className="mt-8 max-w-xl mx-auto bg-white p-6 rounded shadow"
      name="contact"
      method="post"
      data-netlify="true"
    >
      <div className="mb-4">
        <label className="block text-gray-600 mb-2">Name</label>
        <input
          type="text"
          name="name"
          className="w-full px-4 py-2 border rounded"
          placeholder="Your Name"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-600 mb-2">Email</label>
        <input
          type="email"
          name="email"
          className="w-full px-4 py-2 border rounded"
          placeholder="Your Email"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-600 mb-2">Message</label>
        <textarea
          name="message"
          className="w-full px-4 py-2 border rounded"
          placeholder="Your Message"
        ></textarea>
      </div>
      <button
        type="submit"
        className="w-full py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-600"
      >
        Send Message
      </button>
    </form> */}
    <div className="text-center mt-4">
      <p>Contact me directly at matt@mattallan.com.au</p>
    </div>
  </section>
  );
});

export default ContactSection;
