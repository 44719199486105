import React from 'react';

interface HeroSectionProps {
  onContactClick: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ onContactClick }) => (
  <section className="bg-blue-100 py-20 text-center">
    <h1 className="text-4xl font-bold text-blue-800">Flight Deck Mate</h1>
    <p className="mt-4 text-lg text-blue-600">
      Empowering pilots and aviation organizations with instant access to relevant regulatory information.
    </p>
    <button
      onClick={onContactClick} 
      className="mt-6 px-6 py-3 bg-blue-500 text-white font-medium rounded hover:bg-blue-600"
    >
      Contact Us
    </button>
  </section>
);

export default HeroSection;
