import React from 'react';

const AboutSection = () => (
  <section className="py-12 px-6 bg-gray-50 text-gray-700">
    <h2 className="text-3xl font-semibold text-center text-gray-800">What we do?</h2>
    <p className="mt-4 max-w-2xl mx-auto text-center">
      Chief pilots often struggle with managing and understanding the nuance of many conflicting regulatory documents. 
      Our mobile native app offers quick recall of relevant sections with a company-specific suggestion & tone.
    </p>
  </section>
);

export default AboutSection;
