import React from 'react';

const FeaturesSection = () => (
  <section className="py-12 bg-gray-200">
    <h2 className="text-3xl font-semibold text-center text-gray-800">Features</h2>
    <div className="mt-8 max-w-4xl mx-auto grid gap-6 sm:grid-cols-1 md:grid-cols-3">
      <div className="p-6 bg-gray-100 rounded shadow">
        <h3 className="text-xl font-bold text-gray-700">Save Time</h3>
        <p className="mt-2 text-gray-600">Empower pilots in the field to access relevant regulatory sections without digging through PDFs.</p>
      </div>
      <div className="p-6 bg-gray-100 rounded shadow">
        <h3 className="text-xl font-bold text-gray-700">Ensure Compliance</h3>
        <p className="mt-2 text-gray-600">Ensure pilots access to the most up-to-date legislative and company policies.</p>
      </div>
      <div className="p-6 bg-gray-100 rounded shadow">
        <h3 className="text-xl font-bold text-gray-700">Extensible</h3>
        <p className="mt-2 text-gray-600">Adapt the app for handbooks, maintenance, flight schools, charter operations.</p>
      </div>
    </div>
  </section>
);

export default FeaturesSection;
